import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { ParticlesBackground } from './Particles';

export function Hero() {
  const { t } = useLanguage();

  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-black via-black to-zinc-900/90">
      <ParticlesBackground />
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative z-10 text-center px-4"
      >
        {/* Titre principal */}
        <motion.h1 
          className="text-5xl md:text-7xl font-bold mb-6 text-shadow-lg font-cinzel"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {String(t('hero.title'))}
        </motion.h1>

        {/* Sous-titre */}
        <motion.p 
          className="text-xl md:text-2xl mb-8 max-w-2xl mx-auto text-shadow-lg font-cinzel"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          {String(t('hero.subtitle'))}
        </motion.p>

        {/* Bouton d'appel à l'action */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <Link
            to="/decouvrir"
            className="inline-flex items-center px-8 py-3 border-2 border-white hover:bg-white hover:text-black transition-all duration-300"
          >
            {String(t('hero.cta'))}
            <ChevronDown className="ml-2" size={20} />
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
}
