import { ArrowLeft, Instagram, Music, Twitter, Youtube } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { CosmicAnimation } from './CosmicAnimation';

export function SocialMedia() {
  const [showContent, setShowContent] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const socialLinks = [
    {
      icon: <Instagram size={32} />,
      url: 'https://www.instagram.com/furyoofficial/profilecard/?igsh=MWY1Z2swbHM1Nnpjaw==',
      label: 'Instagram',
    },
    {
      icon: <Youtube size={32} />,
      url: 'https://youtube.com/@furyoofficial?si=dPJWgU6rH0axOSq6',
      label: 'YouTube',
    },
    {
      icon: <Twitter size={32} />,
      url: 'https://x.com/furyo_official?s=21&t=zDwKAF8wpBxKFZjjRiPN1w',
      label: 'Twitter',
    },
    {
      icon: <Music size={32} />,
      url: 'https://tiktok.com/@furyoofficial?_t=8oSoTNPgQGv&_r=1',
      label: 'TikTok',
    },
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center relative overflow-hidden">
      <CosmicAnimation />
      
      {/* Back Navigation Arrow */}
      <Link
        to="/"
        className={`absolute top-8 left-8 z-20 p-3 rounded-full bg-zinc-900/80 backdrop-blur-sm 
          hover:bg-zinc-800 transition-all duration-300 transform hover:scale-110 
          ${showContent ? 'opacity-100' : 'opacity-0'}`}
        aria-label="Retour à l'accueil"
      >
        <ArrowLeft size={24} className="text-white" />
      </Link>
      
      <div className={`text-center z-10 px-4 transition-all duration-1000 ${
        showContent ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-10'
      }`}>
        <h1 className="text-4xl md:text-5xl font-bold mb-4 font-cinzel">
          {t('social.title')}
        </h1>
        <p className="text-xl text-gray-300 mb-12 max-w-2xl">
          {t('social.subtitle')}
        </p>
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-12">
          {socialLinks.map((link, index) => (
            <a
              key={link.label}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex flex-col items-center space-y-2 transition-all duration-500 hover:scale-110 ${
                showContent 
                  ? 'opacity-100 transform translate-y-0' 
                  : 'opacity-0 transform translate-y-10'
              }`}
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              <div className="w-16 h-16 rounded-full bg-zinc-900 flex items-center justify-center hover:bg-zinc-800 transition-colors duration-300">
                {link.icon}
              </div>
              <span className="text-sm font-medium">{link.label}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
