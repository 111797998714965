import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

interface AboutLogoProps {
  isVisible: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

export function AboutLogo({ isVisible, containerRef }: AboutLogoProps) {
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const y = useTransform(scrollYProgress, [0, 0.5, 1], [50, 0, -50]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0.6, 1, 1, 0.6]);

  return (
    <motion.div 
      className="relative h-full"
      style={{ scale, y, opacity }}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ 
        opacity: isVisible ? 1 : 0,
        scale: isVisible ? 1 : 0.9,
      }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div 
        className="sticky top-24 w-full aspect-square md:aspect-video rounded-lg overflow-hidden shadow-2xl"
      >
        <div 
          className="absolute inset-0 bg-cover bg-center rounded-lg shadow-2xl brightness-90 contrast-110 transform transition-transform duration-700 hover:scale-105"
          style={{
            backgroundImage: 'url("https://res.cloudinary.com/dyx9nyz70/image/upload/v1733194780/d9pedickrmnf2asmafdp.jpg")',
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <div className="absolute inset-0 bg-black/20 rounded-lg" />
      </div>
    </motion.div>
  );
}