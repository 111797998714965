import { useLanguage } from '../contexts/LanguageContext';
import { EbookGrid } from './ebooks/EbookGrid';

export function Ebooks() {
  const { t } = useLanguage();

  return (
    <section 
      id="ebooks" 
      className="w-full bg-black relative py-16 md:py-24 overflow-hidden"
      style={{ 
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        transform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden',
        willChange: 'transform'
      }}
    >
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center font-cinzel">
          {t('ebooks.title').toString()}
        </h2>
        <p className="text-lg md:text-xl text-center max-w-3xl mx-auto mb-12 text-gray-300">
          {t('ebooks.description').toString()}
        </p>
        <EbookGrid />
      </div>
    </section>
  );
}
