import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useInView } from '../hooks/useInView';

export function Join() {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const { t } = useLanguage();

  return (
    <section id="join" className="py-24 bg-zinc-900 relative overflow-hidden w-full">
      <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent opacity-50" />
      <div className="absolute inset-0 shimmer-bg" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative" ref={ref}>
        <h2
          className={`text-4xl font-bold mb-12 transform transition-all duration-700 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          <span className="font-cinzel">{String(t('join.title'))}</span>
        </h2>
        <p
          className={`text-xl max-w-3xl mx-auto mb-12 text-gray-300 transform transition-all duration-700 delay-200 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          {String(t('join.description'))}
        </p>
        <Link
          to="/rejoignez-nous"
          className={`inline-block px-12 py-4 border-2 border-white hover:bg-white hover:text-black transition-colors duration-300 text-lg transform transition-all duration-700 delay-400 hover-glow ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          {String(t('join.cta'))}
        </Link>
      </div>
    </section>
  );
}
