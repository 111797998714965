import { EbookCTA } from './EbookCTA';
import { EbookVideo } from './EbookVideo';

interface EbookContent {
  title: string;
  subtitle: string;
  mainText: string;
  cta: string;
  stripeLink: string;
}

interface EbookSectionProps {
  lang: 'french' | 'english';
}

export function EbookSection({ lang }: EbookSectionProps) {
  const content: { french: EbookContent; english: EbookContent } = {
    french: {
      title: "Éveil de puissance intérieure",
      subtitle: "Le guide ultime pour déclencher votre transformation personnelle",
      mainText: `
FURYO n’est pas simplement une marque. C’est une révolution intérieure, une force universelle qui vous invite à dépasser vos limites, à vous libérer de vos entraves et à devenir la meilleure version de vous-même. Cet e-book n’est pas un simple livre, c’est un outil transformationnel, forgé pour les ambitieux, les visionnaires et les audacieux qui aspirent à l’excellence.

Chaque page est un pas vers votre élévation. Chaque chapitre, une clé pour débloquer votre potentiel. Si vous êtes prêt à embrasser le changement et à réécrire votre destinée, cet e-book est fait pour vous.

Pourquoi cet e-book est différent ?

Ce guide repose sur des techniques exclusives, issues de la psychologie humaine, des neurosciences, et des stratégies testées par les leaders les plus influents au monde. Chaque mot, chaque exercice, chaque méthode est conçu pour garantir des résultats concrets et rapides.

Voici pourquoi cet e-book se démarque :
	•	Un contenu unique et exclusif : Vous découvrirez des méthodes et des approches qui ne se trouvent nulle part ailleurs.
	•	Un chemin clair et structuré : Pas de place pour les promesses vagues. Cet e-book offre un plan précis, étape par étape, pour libérer votre puissance intérieure.
	•	Un impact immédiat et durable : Les résultats sont visibles dès les premières semaines, avec une transformation mesurable en seulement 90 jours.
	•	Une invitation à l’élite : Rejoignez une communauté de leaders, visionnaires et ambitieux qui refusent de se contenter de moins que l’excellence.

Ce que vous découvrirez à l’intérieur :

Semaine 1 : Poser les bases de votre transformation intérieure
	•	Chapitre 1 : Définir une vision claire et des objectifs transcendants.
Identifiez votre “pourquoi” intérieur, cette boussole qui vous guidera vers des objectifs alignés à vos valeurs profondes. Clarifiez votre vision et découvrez ce qui vous motive réellement.
	•	Exercices pratiques : Techniques puissantes pour éliminer vos blocages et activer votre potentiel caché.

Semaine 2 : Discipline inébranlable
	•	Chapitre 2 : Développer une routine de performance.
Apprenez à intégrer les habitudes des plus grands leaders dans votre quotidien pour maximiser votre énergie, votre productivité, et votre concentration.
	•	Bonus technique : Techniques avancées de visualisation pour anticiper les défis et les surmonter avec succès.

Semaine 3 : Gestion émotionnelle avancée
	•	Chapitre 3 : Maîtrise de soi et transformation des émotions.
Libérez-vous des émotions négatives, comme la peur et la colère, et transformez-les en énergie constructive. Apprenez à utiliser vos émotions comme des leviers de progression personnelle.
	•	Exercice de méditation : Des outils inédits pour calmer votre esprit, réduire le stress, et maintenir un focus imperturbable.

Semaine 4 : Le chemin de l’élite
	•	Chapitre 4 : Accéder à votre potentiel ultime.
Découvrez des stratégies avancées pour transcender vos limites et incarner la meilleure version de vous-même. Ce chapitre explore comment exploiter chaque opportunité pour atteindre des résultats extraordinaires dans tous les domaines de votre vie.

Des bonus exclusifs réservés à l’élite FURYO :

En rejoignant cette aventure, vous débloquez également :
	•	Techniques avancées de méditation : Une méthode secrète combinant visualisation, gestion du temps et transformation émotionnelle pour des résultats optimaux.
	•	L’art de transformer la colère en force : Une approche inédite pour canaliser vos émotions brutes en énergie positive et productive.
	•	Un système d’action immédiat : Des étapes concrètes et mesurables pour progresser sans jamais douter de votre direction.

Quels résultats pouvez-vous espérer ?

En suivant les enseignements de cet e-book, vous serez capable de :
	•	Forger une discipline inébranlable, clé pour atteindre vos objectifs les plus ambitieux.
	•	Transcender vos limites émotionnelles, en transformant chaque obstacle en opportunité.
	•	Créer une routine quotidienne de performance, qui soutient vos aspirations personnelles et professionnelles.
	•	Atteindre des résultats visibles en seulement 90 jours, grâce à des méthodes conçues pour maximiser votre progression.

Pourquoi choisir cet e-book ?

Cet e-book n’est pas un simple achat. C’est un investissement dans votre potentiel et votre avenir. C’est une invitation à rejoindre l’élite FURYO, un groupe de leaders et de visionnaires qui refusent de se contenter de moins que l’excellence.
	•	Un engagement sans risque : Si vous ne constatez pas une transformation notable après avoir suivi nos méthodes, nous vous remboursons intégralement.
	•	Un accès immédiat : Téléchargez votre exemplaire dès aujourd’hui et commencez votre transformation maintenant.

Une opportunité que vous ne pouvez pas manquer

Le moment est venu de prendre le contrôle total de votre destinée. Ce guide est une clé unique qui a déjà transformé des milliers de vies. La vôtre pourrait être la prochaine.

Ne laissez pas vos doutes ou vos peurs vous retenir. Faites le choix de la grandeur et rejoignez l’élite FURYO dès aujourd’hui.

-> Accéder Maintenant ci-dessous
      `,
      cta: "Accéder Maintenant",
      stripeLink: "https://buy.stripe.com/14k7sz9RT0Tm8Y8144",
    },
    english: {
      title: "Awakening Inner Power",
      subtitle: "The Ultimate Guide to Unleash Your Personal Transformation",
      mainText: `
FURYO is not just a brand. It’s an inner revolution, a universal force inviting you to surpass your limits, break free from constraints, and become the best version of yourself. This e-book is not merely a book—it’s a transformational tool, crafted for the ambitious, the visionaries, and the bold who strive for excellence.

Every page is a step toward your elevation. Every chapter, a key to unlocking your potential. If you’re ready to embrace change and rewrite your destiny, this e-book is for you.

What Makes This E-book Different?

This guide is based on exclusive techniques drawn from human psychology, neuroscience, and strategies tested by the world’s most influential leaders. Every word, every exercise, every method is designed to deliver tangible and rapid results.

Here’s why this e-book stands out:
	•	Unique and Exclusive Content: Discover methods and approaches you won’t find anywhere else.
	•	A Clear and Structured Path: No vague promises. This e-book provides a precise, step-by-step plan to unlock your inner power.
	•	Immediate and Lasting Impact: Results are noticeable within the first few weeks, with measurable transformation in just 90 days.
	•	An Invitation to the Elite: Join a community of leaders, visionaries, and ambitious individuals who refuse to settle for anything less than excellence.

What You’ll Discover Inside:

Week 1: Laying the Foundations of Your Inner Transformation
	•	Chapter 1: Defining a Clear Vision and Transcendent Goals
Identify your inner “why,” the compass that will guide you toward goals aligned with your deepest values. Clarify your vision and uncover what truly motivates you.
	•	Practical Exercises: Powerful techniques to eliminate your blocks and activate your hidden potential.

Week 2: Unshakable Discipline
	•	Chapter 2: Developing a Performance Routine
Learn how to integrate the habits of the greatest leaders into your daily life to maximize your energy, productivity, and focus.
	•	Bonus Technique: Advanced visualization methods to anticipate challenges and overcome them successfully.

Week 3: Advanced Emotional Management
	•	Chapter 3: Mastery of Self and Emotional Transformation
Free yourself from negative emotions like fear and anger, transforming them into constructive energy. Learn to use your emotions as levers for personal growth.
	•	Meditation Exercise: Unique tools to calm your mind, reduce stress, and maintain unwavering focus.

Week 4: The Path to the Elite
	•	Chapter 4: Accessing Your Ultimate Potential
Discover advanced strategies to transcend your limits and embody the best version of yourself. This chapter explores how to seize every opportunity to achieve extraordinary results in all areas of your life.

Exclusive Bonuses Reserved for the FURYO Elite:

By joining this journey, you’ll also unlock:
	•	Advanced Meditation Techniques: A secret method combining visualization, time management, and emotional transformation for optimal results.
	•	The Art of Transforming Anger into Strength: A groundbreaking approach to channeling raw emotions into positive and productive energy.
	•	An Immediate Action System: Concrete and measurable steps to progress without ever doubting your direction.

What Results Can You Expect?

By following the teachings of this e-book, you will be able to:
	•	Forge unshakable discipline, the key to achieving your most ambitious goals.
	•	Transcend emotional barriers by turning every obstacle into an opportunity.
	•	Create a daily performance routine that supports your personal and professional aspirations.
	•	Achieve visible results in just 90 days through methods designed to maximize your growth.

Why Choose This E-book?

This e-book is not just a purchase. It’s an investment in your potential and your future. It’s an invitation to join the FURYO elite, a group of leaders and visionaries who refuse to settle for anything less than excellence.
	•	Risk-Free Commitment: If you don’t notice a significant transformation after following our methods, we will refund you in full.
	•	Immediate Access: Download your copy today and start your transformation now.

An Opportunity You Can’t Miss

The time has come to take full control of your destiny. This guide is a unique key that has already transformed thousands of lives. Yours could be next.

Don’t let doubt or fear hold you back. Choose greatness and join the FURYO elite today.

-> Access Now Below
      `,
      cta: "Buy Now",
      stripeLink: "https://buy.stripe.com/4gw3cj8NP9pSdeo4gh",
    },
  };

  const selectedContent = lang === 'english' ? content.english : content.french;

  return (
    <div className="bg-zinc-900/95 rounded-lg p-4 md:p-6 shadow-lg">
      {/* Titre et sous-titre */}
      <div className="text-center mb-6">
        <h1 className="text-3xl font-bold font-cinzel mb-2">{selectedContent.title}</h1>
        <h2 className="text-xl text-gray-300">{selectedContent.subtitle}</h2>
      </div>

      {/* Vidéo */}
      <EbookVideo lang={lang} />

      {/* Texte principal */}
      <div className="prose prose-invert max-w-none whitespace-pre-line mt-6">
        {selectedContent.mainText}
      </div>

      {/* CTA */}
      <EbookCTA
        stripeLink={selectedContent.stripeLink}
        ctaText={selectedContent.cta}
      />
    </div>
  );
}