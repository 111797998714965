interface EbookVideoProps {
  lang: 'french' | 'english';
}

export function EbookVideo({ lang }: EbookVideoProps) {
  const videoContent = {
    french: {
      poster: "https://res.cloudinary.com/dyx9nyz70/image/upload/v1733188956/dn4knoayjxt1wgd3yusu.jpg",
      video: "https://res.cloudinary.com/dyx9nyz70/video/upload/v1733188956/dn4knoayjxt1wgd3yusu.mp4",
    },
    english: {
      poster: "https://res.cloudinary.com/dyx9nyz70/image/upload/v1733189198/uvenkhbm0j9c17pvmfgv.jpg",
      video: "https://res.cloudinary.com/dyx9nyz70/video/upload/v1733189198/uvenkhbm0j9c17pvmfgv.mp4",
    },
  };

  const { poster, video } = videoContent[lang];

  return (
    <div className="aspect-[9/16] rounded-lg shadow-lg overflow-hidden bg-zinc-800">
      <video
        className="w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        poster={poster}
        src={video}
      />
    </div>
  );
}