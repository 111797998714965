export const translations = {
  fr: {
    nav: {
      about: "À propos",
      ebooks: "E-Books",
      join: "Rejoindre",
      contact: "Contact",
    },
    hero: {
      title: "FURYO",
      subtitle: "Puissance et Progrès",
      cta: "Découvrir",
    },
    about: {
      title: "À propos de FURYO",
      vision: {
        title: "Notre Vision",
        description: `Furyo est une rébellion silencieuse, un cri universel pour ceux qui refusent d'accepter les limites imposées par la peur, les échecs et les doutes. 
        Notre vision est de révéler la puissance enfouie en chacun de nous, cette force brute capable de transformer les obstacles en tremplins et les défaites en victoires.

        Nous voyons un monde où chaque individu cesse de survivre pour enfin exister pleinement, en prenant la responsabilité de son propre destin. À travers Furyo, nous construisons une communauté mondiale unie, non par des dogmes, mais par une quête inébranlable de progrès. 
        Un monde où chacun devient l'architecte de sa propre renaissance et un catalyseur de changement pour ceux qui l'entourent.

        Furyo n'est pas une simple vision. C'est une promesse : celle d'un avenir forgé par la résilience, nourri par le courage, et illuminé par la transformation personnelle.`,
      },
      mission: {
        title: "Notre Mission",
        description: `Notre mission est de raviver cette flamme que la routine, les échecs, et les peurs ont tenté d'étouffer. 
        Nous ne sommes pas là pour offrir de simples mots ou idées : Furyo est une direction, un guide et une force motrice pour ceux qui aspirent à transcender l'ordinaire.

        À travers des messages percutants et un contenu d'une qualité exceptionnelle, nous cultivons une connexion puissante entre l'esprit et l'action. 
        Nous croyons que chaque individu possède une étincelle capable d'allumer un incendie de transformation, une énergie brute qui, lorsqu'elle est maîtrisée, peut redéfinir ce qui est possible.

        Rejoindre Furyo, c'est plus qu'un engagement. C'est une déclaration de guerre à l'inaction et à la médiocrité. 
        Nous vous montrons que chaque pas compte, que chaque action, aussi infime soit-elle, peut être le début d'un mouvement monumental. 
        Ensemble, nous transformons des rêves en réalités et des volontés en légendes.`,
      },
    },
    ebooks: {
      title: "E-Books",
      description: "Découvrez nos e-books pour transformer votre vie.",
      french: {
        title: "Éveil de puissance intérieure",
        subtitle: "Le guide ultime pour déclencher votre transformation personnelle",
        mainText: "FURYO n'est pas simplement une marque. C'est une révolution intérieure...",
        cta: "Accéder Maintenant",
      },
      english: {
        title: "Awakening Inner Power",
        subtitle: "The Ultimate Guide to Unleash Your Personal Transformation",
        mainText: "FURYO is not just a brand. It is an inner revolution, a universal force...",
        cta: "Buy Now",
      },
    },
    join: {
      title: "Rejoignez FURYO",
      description: "Rejoignez notre communauté pour incarner la puissance et l'excellence.",
      cta: "Rejoignez-nous Maintenant",
    },
    contact: {
      title: "Contact",
      description: "Une question ? Une proposition de collaboration ? N'hésitez pas à nous contacter.",
      form: {
        name: "Nom",
        email: "Email",
        message: "Message",
        submit: "Envoyer",
        submitting: "Envoi en cours...",
        errors: {
          required: "Tous les champs sont requis.",
          email: "Veuillez entrer une adresse email valide.",
          generic: "Une erreur est survenue. Veuillez réessayer plus tard.",
        },
      },
    },
    social: {
      title: "Rejoignez-nous",
      subtitle: "Suivez Furyo sur les réseaux sociaux et rejoignez notre communauté.",
    },
    footer: {
      legal: "Mentions légales",
      privacy: "Politique de confidentialité",
      terms: "Conditions d'utilisation",
      copyright: "© 2024 FURYO. Tous droits réservés.",
    },
  },
  en: {
    nav: {
      about: "About",
      ebooks: "E-Books",
      join: "Join",
      contact: "Contact",
    },
    hero: {
      title: "FURYO",
      subtitle: "Power and Progress",
      cta: "Discover",
    },
    about: {
      title: "About FURYO",
      vision: {
        title: "Our Vision",
        description: `Furyo is a silent rebellion, a universal cry for those who refuse to accept the limits imposed by fear, failure, and doubt. 
        Our vision is to reveal the power buried within each of us, that raw force capable of transforming obstacles into stepping stones and defeats into victories.

        We see a world where each individual stops surviving to finally exist fully, taking responsibility for their own destiny. Through Furyo, we are building a united global community, not by dogmas, but through an unwavering quest for progress. 
        A world where everyone becomes the architect of their own rebirth and a catalyst for change for those around them.

        Furyo is not just a vision. It's a promise: one of a future forged by resilience, nourished by courage, and illuminated by personal transformation.`,
      },
      mission: {
        title: "Our Mission",
        description: `Our mission is to rekindle that flame that routine, failures, and fears have tried to extinguish. 
        We are not here to offer mere words or ideas: Furyo is a direction, a guide, and a driving force for those who aspire to transcend the ordinary.

        Through impactful messages and exceptional content, we cultivate a powerful connection between mind and action. 
        We believe that every individual possesses a spark capable of igniting a fire of transformation, a raw energy that, when mastered, can redefine what is possible.

        Joining Furyo is more than a commitment. It's a declaration of war on inaction and mediocrity. 
        We show you that every step counts, that every action, no matter how small, can be the beginning of a monumental movement. 
        Together, we transform dreams into realities and wills into legends.`,
      },
    },
    ebooks: {
      title: "E-Books",
      description: "Discover our e-books to transform your life.",
      french: {
        title: "Éveil de puissance intérieure",
        subtitle: "Le guide ultime pour déclencher votre transformation personnelle",
        mainText: "FURYO n'est pas simplement une marque. C'est une révolution intérieure...",
        cta: "Accéder Maintenant",
      },
      english: {
        title: "Awakening Inner Power",
        subtitle: "The Ultimate Guide to Unleash Your Personal Transformation",
        mainText: "FURYO is not just a brand. It is an inner revolution, a universal force...",
        cta: "Buy Now",
      },
    },
    join: {
      title: "Join FURYO",
      description: "Join our community to embody power and excellence.",
      cta: "Join Us Now",
    },
    contact: {
      title: "Contact",
      description: "Have a question? A collaboration proposal? Don't hesitate to contact us.",
      form: {
        name: "Name",
        email: "Email",
        message: "Message",
        submit: "Send",
        submitting: "Sending...",
        errors: {
          required: "All fields are required.",
          email: "Please enter a valid email address.",
          generic: "An error occurred. Please try again later.",
        },
      },
    },
    social: {
      title: "Join Us",
      subtitle: "Follow Furyo on social media and join our community.",
    },
    footer: {
      legal: "Legal Notice",
      privacy: "Privacy Policy",
      terms: "Terms of Service",
      copyright: "© 2024 FURYO. All rights reserved.",
    },
  },
};