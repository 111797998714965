import { EbookSection } from './EbookSection';

export function EbookGrid() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
      {/* Colonne gauche : Français */}
      <EbookSection lang="french" />
      {/* Colonne droite : Anglais */}
      <EbookSection lang="english" />
    </div>
  );
}
