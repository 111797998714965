import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';

interface AboutContentProps {
  isVisible: boolean;
}

export function AboutContent({ isVisible }: AboutContentProps) {
  const { t } = useLanguage();

  return (
    <motion.div 
      className="transform transition-all duration-700"
      initial={{ opacity: 0, x: -20 }}
      animate={{ 
        opacity: isVisible ? 1 : 0,
        x: isVisible ? 0 : -20 
      }}
      transition={{ duration: 0.7, delay: 0.3 }}
    >
      <h3 className="text-2xl font-semibold mb-4 font-cinzel">{String(t('about.vision.title'))}</h3>
      <div className="text-gray-300 mb-8 leading-relaxed whitespace-pre-line">
        {String(t('about.vision.description'))}
      </div>
      <h3 className="text-2xl font-semibold mb-4 font-cinzel">{String(t('about.mission.title'))}</h3>
      <div className="text-gray-300 leading-relaxed whitespace-pre-line">
        {String(t('about.mission.description'))}
      </div>
    </motion.div>
  );
}