import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useInView } from '../hooks/useInView';
import { ContactForm } from './ContactForm';

export function Contact() {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const { t } = useLanguage();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmitSuccess = () => {
    setSubmitted(true);
    setTimeout(() => setSubmitted(false), 3000);
  };

  return (
    <section id="contact" className="py-24 bg-black relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-zinc-900/50 to-black pointer-events-none" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <h2 
          className={`text-4xl font-bold mb-12 text-center transform transition-all duration-700 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          <span className="font-cinzel">{String(t('contact.title'))}</span>
        </h2>
        <div className="grid md:grid-cols-2 gap-12" ref={ref}>
          <div 
            className={`transform transition-all duration-700 delay-300 ${
              isVisible ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'
            }`}
          >
            <h3 className="text-2xl font-semibold mb-6 font-cinzel">{String(t('contact.title'))}</h3>
            <p className="text-gray-300 mb-8">
              {String(t('contact.description'))}
            </p>
            <Link 
              to="/rejoignez-nous" 
              className="inline-block text-lg hover:text-gray-300 transition-colors duration-300"
            >
              Rejoignez-nous
            </Link>
          </div>
          <div 
            className={`transform transition-all duration-700 delay-500 relative ${
              isVisible ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'
            }`}
          >
            <ContactForm onSubmitSuccess={handleSubmitSuccess} />
            {submitted && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/80 rounded-lg animate-fade-in">
                <p className="text-green-400 text-lg">Message envoyé avec succès!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
