import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ParticlesBackground } from './Particles';

export function LegalNotice() {
  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      <ParticlesBackground />
      
      <Link
        to="/"
        className="absolute top-8 left-8 z-20 p-3 rounded-full bg-zinc-900/80 backdrop-blur-sm 
          hover:bg-zinc-800 transition-all duration-300 transform hover:scale-110"
      >
        <ArrowLeft size={24} className="text-white" />
      </Link>

      <div className="container mx-auto px-4 py-24 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto bg-zinc-900/80 backdrop-blur-md p-8 rounded-lg shadow-xl"
        >
          <h1 className="text-4xl font-bold mb-8 font-cinzel">Mentions légales</h1>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Éditeur du site</h2>
            <p className="mb-4">Ce site web est édité par :</p>
            <ul className="list-disc list-inside space-y-2 text-gray-300">
              <li>Nom : FuryoOfficial</li>
              <li>Statut : Indépendant</li>
              <li>Adresse : Bruxelles, Belgique</li>
              <li>E-mail : info@furyoofficial.com</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Hébergement du site</h2>
            <p className="text-gray-300">
              Le site est hébergé sur des serveurs fiables situés dans l'Union européenne.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Propriété intellectuelle</h2>
            <p className="text-gray-300">
              Tout le contenu disponible sur ce site (textes, images, vidéos, logos, graphismes, etc.) 
              est protégé par le droit d'auteur et la propriété intellectuelle. Toute reproduction, 
              distribution, modification ou utilisation, même partielle, est interdite sans 
              l'autorisation préalable de FuryoOfficial.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Données personnelles</h2>
            <p className="text-gray-300">
              Les données collectées sur ce site sont traitées conformément au Règlement Général 
              sur la Protection des Données (RGPD). Pour toute question relative à la gestion de 
              vos données personnelles, veuillez nous contacter à l'adresse suivante : 
              info@furyoofficial.com.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Responsabilité</h2>
            <p className="text-gray-300">
              FuryoOfficial ne saurait être tenu responsable des dommages directs ou indirects 
              résultant de l'accès ou de l'utilisation de ce site, y compris en cas d'erreur 
              ou d'omission dans le contenu.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4">Contact</h2>
            <p className="text-gray-300">
              Pour toute demande ou information supplémentaire, vous pouvez nous joindre à 
              l'adresse suivante : info@furyoofficial.com.
            </p>
          </section>
        </motion.div>
      </div>
    </div>
  );
}
