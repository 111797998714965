import React from 'react';
import { Navigation } from './components/Navigation';
import { Hero } from './components/Hero';
import { About } from './components/About';
import { Ebooks } from './components/Ebooks';
import { Join } from './components/Join';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { SocialMedia } from './components/SocialMedia';
import { Discover } from './components/Discover';
import { LegalNotice } from './components/LegalNotice';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsOfService } from './components/TermsOfService';
import { ScrollProgress } from './components/ScrollProgress';
import { PageTransition } from './components/PageTransition';
import { LanguageProvider } from './contexts/LanguageContext';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useViewportHeight } from './hooks/useViewportHeight';

function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  useViewportHeight();

  return (
    <BrowserRouter>
      <LanguageProvider>
        <ScrollProgress />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/mentions-legales" element={
              <PageTransition>
                <LegalNotice />
              </PageTransition>
            } />
            <Route path="/politique-de-confidentialite" element={
              <PageTransition>
                <PrivacyPolicy />
              </PageTransition>
            } />
            <Route path="/conditions-utilisation" element={
              <PageTransition>
                <TermsOfService />
              </PageTransition>
            } />
            <Route path="/rejoignez-nous" element={
              <PageTransition>
                <SocialMedia />
              </PageTransition>
            } />
            <Route path="/decouvrir" element={
              <PageTransition>
                <Discover />
              </PageTransition>
            } />
            <Route
              path="/"
              element={
                <PageTransition>
                  <div className="min-h-screen bg-black text-white relative">
                    <Navigation isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
                    <Hero />
                    <About />
                    <Ebooks />
                    <Join />
                    <Contact />
                    <Footer />
                  </div>
                </PageTransition>
              }
            />
          </Routes>
        </AnimatePresence>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;