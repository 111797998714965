import { Menu, X } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';
import { LanguageSelector } from './LanguageSelector';

interface NavigationProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

export function Navigation({ isMenuOpen, setIsMenuOpen }: NavigationProps) {
  const { t } = useLanguage();

  return (
    <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <span className="text-2xl font-bold tracking-wider font-cinzel logo-hover">
              FuryoOfficial
            </span>
          </div>
          <div className="hidden md:flex md:flex-col md:items-end">
            <LanguageSelector />
            <div className="mt-2 flex items-baseline space-x-8">
              <a href="#about" className="hover:text-gray-300 transition-colors">
                {String(t('nav.about'))}
              </a>
              <a href="#ebooks" className="hover:text-gray-300 transition-colors">
                {String(t('nav.ebooks'))}
              </a>
              <a href="#join" className="hover:text-gray-300 transition-colors">
                {String(t('nav.join'))}
              </a>
              <a href="#contact" className="hover:text-gray-300 transition-colors">
                {String(t('nav.contact'))}
              </a>
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md hover:text-gray-300"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-black">
            <div className="px-3 py-2">
              <LanguageSelector />
            </div>
            <a href="#about" className="block px-3 py-2 hover:text-gray-300">
              {String(t('nav.about'))}
            </a>
            <a href="#ebooks" className="block px-3 py-2 hover:text-gray-300">
              {String(t('nav.ebooks'))}
            </a>
            <a href="#join" className="block px-3 py-2 hover:text-gray-300">
              {String(t('nav.join'))}
            </a>
            <a href="#contact" className="block px-3 py-2 hover:text-gray-300">
              {String(t('nav.contact'))}
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}
