import { useLanguage } from '../contexts/LanguageContext';

export function LanguageSelector() {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex items-center space-x-4 bg-black/70 backdrop-blur-md px-4 py-2 rounded-full shadow-lg border border-white/10 hover:border-white/20 transition-all duration-300">
      <button
        onClick={() => setLanguage('fr')}
        className={`text-sm font-medium transition-all duration-300 hover:scale-105 ${
          language === 'fr' 
            ? 'text-white border-b-2 border-white' 
            : 'text-gray-400 hover:text-white'
        }`}
        aria-label="Switch to French"
      >
        🇫🇷 Français
      </button>
      <span className="text-gray-400">|</span>
      <button
        onClick={() => setLanguage('en')}
        className={`text-sm font-medium transition-all duration-300 hover:scale-105 ${
          language === 'en' 
            ? 'text-white border-b-2 border-white' 
            : 'text-gray-400 hover:text-white'
        }`}
        aria-label="Switch to English"
      >
        🇬🇧 English
      </button>
    </div>
  );
}
