export function PrivacyContent() {
  return (
    <>
      <section className="mb-8">
        <h1 className="text-3xl font-bold mb-6">Politique de Confidentialité </h1>
        <p className="text-gray-300 mb-4">
          La présente Politique de Confidentialité décrit la façon dont vos informations personnelles sont recueillies, 
          utilisées et partagées lorsque vous visitez FuryoOfficial.com (le « Site ») ou effectuez un achat.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">1. Informations personnelles recueillies</h2>
        <p className="text-gray-300 mb-4">
          Lorsque vous visitez le Site, nous recueillons automatiquement certaines informations sur votre appareil, notamment :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Les informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil.</li>
          <li>Les pages ou produits que vous consultez, les sites web ou termes de recherche qui vous ont permis d'arriver sur le Site, ainsi que des informations sur la manière dont vous interagissez avec le Site.</li>
        </ul>
        <p className="text-gray-300 mb-4">
          Lorsque vous passez une commande sur le Site, nous recueillons des informations telles que :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Votre nom, adresse de facturation, adresse d'expédition, informations de paiement (y compris les numéros de carte de crédit), e-mail et numéro de téléphone.</li>
        </ul>
        <p className="text-gray-300 mb-4">
          Nous collectons ces données automatiquement à l'aide des technologies suivantes :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Fichiers journaux : Enregistrent les actions se produisant sur le Site, y compris votre adresse IP, le type de navigateur et les pages consultées.</li>
          <li>Balises, pixels et technologies similaires : Permettent de recueillir des informations sur la façon dont vous naviguez sur le Site.</li>
        </ul>
        <p className="text-gray-300">
          Ces données sont utilisées pour :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300">
          <li>Traiter vos commandes et fournir les services demandés.</li>
          <li>Prévenir les fraudes.</li>
          <li>Vous contacter en cas de besoin.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">2. Comment utilisons-nous vos informations personnelles ?</h2>
        <p className="text-gray-300 mb-4">
          Nous utilisons vos Informations personnelles pour :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Traiter vos commandes et gérer la livraison des produits numériques.</li>
          <li>Vous envoyer des confirmations et des factures.</li>
          <li>Répondre à vos questions ou préoccupations.</li>
          <li>Identifier et prévenir les risques de fraude ou tout comportement malveillant.</li>
          <li>Améliorer notre site web et nos services en analysant les interactions des utilisateurs avec le Site.</li>
        </ul>
        <p className="text-gray-300">
          Lorsque vous nous avez donné votre consentement, nous pouvons également vous envoyer des messages marketing pour vous tenir informé(e) de nos produits et promotions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">3. Partage de vos informations personnelles</h2>
        <p className="text-gray-300 mb-4">
          Nous partageons vos informations personnelles avec des prestataires tiers de confiance pour :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Traiter les paiements.</li>
          <li>Analyser le trafic du site (par exemple, via Google Analytics).</li>
          <li>Améliorer nos services (par exemple, via des outils marketing).</li>
        </ul>
        <p className="text-gray-300 mb-4">
          Ces tiers sont tenus de respecter la confidentialité de vos données et de se conformer au RGPD.
        </p>
        <p className="text-gray-300 mb-4">
          Exemple de partenaires :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300">
          <li>
            Google Analytics : Vous pouvez en savoir plus sur leur politique de confidentialité ici :{" "}
            <a
              href="https://www.google.com/intl/fr/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              https://www.google.com/intl/fr/policies/privacy/
            </a>
          </li>
        </ul>
        <p className="text-gray-300">
          Nous pouvons également partager vos informations pour respecter des obligations légales, telles qu'une demande officielle des autorités.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">4. Vos droits</h2>
        <p className="text-gray-300 mb-4">
          Si vous êtes résident(e) de l'Union européenne, vous avez les droits suivants concernant vos données personnelles :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300">
          <li>Accès : Vous pouvez demander une copie des données que nous détenons sur vous.</li>
          <li>Correction : Vous pouvez demander la modification de vos données si elles sont inexactes ou incomplètes.</li>
          <li>Suppression : Vous pouvez demander la suppression de vos données, sauf si nous sommes légalement tenus de les conserver.</li>
        </ul>
        <p className="text-gray-300">
          Pour demander la suppression de vos données, veuillez envoyer un e-mail à{" "}
          <a href="mailto:info@furyoofficial.com" className="text-blue-500 underline">
            info@furyoofficial.com
          </a>.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">5. Conservation des données</h2>
        <p className="text-gray-300">
          Nous conservons vos informations personnelles aussi longtemps que nécessaire pour vous fournir nos services ou conformément aux obligations légales.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">6. Sécurité des données</h2>
        <p className="text-gray-300">
          Nous utilisons des mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, toute perte ou tout vol.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">7. Publicité comportementale et cookies</h2>
        <p className="text-gray-300 mb-4">
          Nous n'utilisons actuellement pas de cookies sur notre site. Toutefois, si des cookies venaient à être intégrés à l'avenir, une Politique relative aux cookies sera mise en place et accessible prochainement pour vous informer de leur utilisation, de leur objectif et de vos droits concernant leur gestion.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">8. Modifications de la Politique de Confidentialité</h2>
        <p className="text-gray-300">
          Nous nous réservons le droit de mettre à jour cette Politique de Confidentialité à tout moment pour refléter des changements dans nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">9. Nous contacter</h2>
        <p className="text-gray-300">
          Pour toute question ou demande liée à la confidentialité, veuillez nous contacter :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300">
          <li>Par e-mail : <a href="mailto:info@furyoofficial.com" className="text-blue-500 underline">info@furyoofficial.com</a></li>
          <li>Adresse postale : Bruxelles, Belgique</li>
        </ul>
      </section>
    </>
  );
}