import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { sendContactForm } from '../services/api';

interface ContactFormProps {
  onSubmitSuccess: () => void;
}

export function ContactForm({ onSubmitSuccess }: ContactFormProps) {
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
    setError(null);
  };

  const validateForm = () => {
    if (!formData.name.trim() || !formData.email.trim() || !formData.message.trim()) {
      setError(String(t('contact.form.errors.required')));
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      setError(String(t('contact.form.errors.email')));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const trimmedData = {
        name: formData.name.trim(),
        email: formData.email.trim(),
        message: formData.message.trim(),
      };

      await sendContactForm(trimmedData);
      setFormData({ name: '', email: '', message: '' });
      onSubmitSuccess();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : String(t('contact.form.errors.generic'));
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6" noValidate>
      <div>
        <label htmlFor="name" className="block text-sm font-medium mb-2">
          {String(t('contact.form.name'))}
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-zinc-900 border border-zinc-700 focus:border-white focus:outline-none rounded-lg transition-colors duration-300"
          disabled={isSubmitting}
          required
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium mb-2">
          {String(t('contact.form.email'))}
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-zinc-900 border border-zinc-700 focus:border-white focus:outline-none rounded-lg transition-colors duration-300"
          disabled={isSubmitting}
          required
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium mb-2">
          {String(t('contact.form.message'))}
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows={4}
          className="w-full px-4 py-2 bg-zinc-900 border border-zinc-700 focus:border-white focus:outline-none rounded-lg transition-colors duration-300"
          disabled={isSubmitting}
          required
        />
      </div>
      {error && (
        <div className="text-red-500 text-sm animate-fade-in">
          {error}
        </div>
      )}
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full py-3 border-2 border-white hover:bg-white hover:text-black transition-colors duration-300 hover-glow ${
          isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {isSubmitting ? String(t('contact.form.submitting')) : String(t('contact.form.submit'))}
      </button>
    </form>
  );
}
