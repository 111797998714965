import React, { createContext, useContext, useState } from 'react';
import { translations } from '../i18n/translations';

// Type pour les langues disponibles
type Language = 'fr' | 'en';

// Interface pour le contexte
interface LanguageContextType {
  language: Language; // Langue actuelle
  setLanguage: (lang: Language) => void; // Fonction pour changer la langue
  t: (key: string) => string; // Retourne une traduction en chaîne
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Fournisseur de contexte de langue
export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('fr'); // Langue par défaut : français

  // Fonction de traduction
  const t = (key: string): string => {
    const keys = key.split('.'); // Divise la clé par les points
    let value: any = translations[language]; // Accède aux traductions pour la langue actuelle

    // Parcourt les clés pour accéder à la valeur
    for (const k of keys) {
      if (typeof value !== 'object' || value === undefined) return key; // Si la clé est invalide, retourne la clé elle-même
      value = value[k];
    }

    // Si une valeur est trouvée, retourne-la. Sinon, retourne la clé.
    return typeof value === 'string' ? value : key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

// Hook personnalisé pour utiliser le contexte de langue
export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
