import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

export function Footer() {
  const { t } = useLanguage();

  return (
    <footer className="py-12 bg-zinc-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <span className="text-2xl font-bold tracking-wider font-cinzel logo-hover">
              FuryoOfficial
            </span>
          </div>
          <div className="flex flex-wrap justify-center gap-6">
            <Link to="/mentions-legales" className="text-sm hover:text-gray-300 transition-colors">
              {String(t('footer.legal'))}
            </Link>
            <Link to="/politique-de-confidentialite" className="text-sm hover:text-gray-300 transition-colors">
              {String(t('footer.privacy'))}
            </Link>
            <Link to="/conditions-utilisation" className="text-sm hover:text-gray-300 transition-colors">
              {String(t('footer.terms'))}
            </Link>
          </div>
        </div>
        <div className="mt-8 text-center text-sm text-gray-400 hover:text-white transition-colors duration-300 cursor-pointer">
          {String(t('footer.copyright'))}
        </div>
      </div>
    </footer>
  );
}
