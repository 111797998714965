import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ParticlesBackground } from './Particles';
import { PrivacyContent } from './privacy/PrivacyContent';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      <ParticlesBackground />
      
      <Link
        to="/"
        className="absolute top-8 left-8 z-20 p-3 rounded-full bg-zinc-900/80 backdrop-blur-sm 
          hover:bg-zinc-800 transition-all duration-300 transform hover:scale-110"
      >
        <ArrowLeft size={24} className="text-white" />
      </Link>

      <div className="container mx-auto px-4 py-24 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto bg-zinc-900/80 backdrop-blur-md p-8 rounded-lg shadow-xl"
        >
          <h1 className="text-4xl font-bold mb-8 font-cinzel">Politique de Confidentialité de FuryoOfficial</h1>
          <PrivacyContent />
        </motion.div>
      </div>
    </div>
  );
}
