import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { ParticlesBackground } from './Particles';

export function Discover() {
  const { language } = useLanguage();

  const videos = [
    {
      url: 'https://res.cloudinary.com/dyx9nyz70/video/upload/v1733227851/tobbrsnowm7ije5l6la7.mp4',
      title: language === 'fr' ? 'Présentation' : 'Introduction',
      description: language === 'fr' ? 'Découvrez notre approche unique' : 'Discover our unique approach'
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      <ParticlesBackground />
      
      <Link
        to="/"
        className="absolute top-8 left-8 z-20 p-3 rounded-full bg-zinc-900/80 backdrop-blur-sm 
          hover:bg-zinc-800 transition-all duration-300 transform hover:scale-110"
        aria-label={language === 'fr' ? 'Retour à l\'accueil' : 'Back to home'}
      >
        <ArrowLeft size={24} className="text-white" />
      </Link>

      <div className="container mx-auto px-4 py-24 relative z-10 flex flex-col items-center">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold text-center mb-12 font-cinzel"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {language === 'fr' ? 'Découvrez Furyo en action' : 'Discover Furyo in Action'}
        </motion.h1>

        <div className="max-w-sm w-full">
          {videos.map((video, index) => (
            <motion.div
              key={index}
              className="bg-zinc-900 rounded-lg overflow-hidden shadow-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
            >
              <div className="aspect-[9/16] relative overflow-hidden">
                <video
                  className="w-full h-full object-cover"
                  controls
                  preload="metadata"
                  playsInline
                >
                  <source src={video.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2 text-center">{video.title}</h3>
                <p className="text-gray-400 text-center">{video.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
