export function TermsContent() {
  return (
    <>
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Aperçu</h2>
        <p className="text-gray-300 mb-4">
          Ce site web est exploité par FuryoOfficial. Partout sur le site, nous employons les termes
          « nous », « notre » et « nos » en référence à FuryoOfficial. Ce site web, y compris l'ensemble
          des informations, outils et services auquel il donne accès, est offert par FuryoOfficial à
          l'utilisateur que vous êtes, à condition que vous acceptiez la totalité des modalités,
          conditions, politiques et avis stipulés ici.
        </p>
        <p className="text-gray-300">
          En visitant notre site et/ou en achetant quelque chose auprès de notre entreprise, vous prenez
          part à notre « Service » et acceptez d'être lié(e) par les modalités et conditions suivantes
          (« Conditions générales », « Conditions d'utilisation »), y compris par les modalités, conditions
          et politiques mentionnées aux présentes et/ou accessibles en hyperlien. Les présentes Conditions
          d'utilisation s'appliquent à tous les utilisateurs du Site, y compris, sans s'y limiter, aux
          individus qui sont des visiteurs, des fournisseurs, des clients, des marchands et/ou des
          fournisseurs de contenu.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 1 – Conditions d'utilisation de la boutique en ligne</h2>
        <p className="text-gray-300 mb-4">
          En acceptant les présentes Conditions d'utilisation, vous déclarez avoir atteint ou dépassé l'âge
          de la majorité dans votre région, province ou État et nous avoir donné l'autorisation de permettre
          à toute personne mineure à votre charge d'utiliser ce site.
        </p>
        <p className="text-gray-300 mb-4">
          Vous ne devez en aucune façon utiliser nos produits à des fins illégales ou non autorisées, ni
          violer des lois de votre juridiction lorsque vous utilisez le Service (y compris, sans toutefois
          s'y limiter, les lois relatives aux droits d'auteur).
        </p>
        <p className="text-gray-300 mb-4">
          Vous ne devez pas transmettre de vers informatique, de virus ou tout code de nature destructrice.
        </p>
        <p className="text-gray-300">
          Une infraction ou une violation de n'importe laquelle des Conditions entraînera la résiliation
          immédiate de vos Services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 2 – Conditions générales</h2>
        <p className="text-gray-300 mb-4">
          Nous nous réservons le droit de refuser de servir quelqu'un à tout moment et pour quelque raison que ce soit.
        </p>
        <p className="text-gray-300 mb-4">
          Vous comprenez que votre contenu (à l'exception des informations relatives à votre carte de crédit)
          peut être transféré sans chiffrement et que cela comprend (a) des transmissions sur plusieurs réseaux ;
          et (b) des changements effectués dans le but de se conformer et de s'adapter aux exigences techniques
          de la connexion de réseaux ou d'appareils. Les informations de votre carte de crédit sont toujours
          chiffrées lors de leur transfert sur les réseaux.
        </p>
        <p className="text-gray-300">
          Les titres utilisés dans le présent accord sont inclus à titre indicatif uniquement et ne limiteront
          ni n'affecteront aucunement ces Conditions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 3 – Exactitude, exhaustivité et actualité des informations</h2>
        <p className="text-gray-300 mb-4">
          Nous ne saurions être tenus responsables si les informations proposées sur ce site sont inexactes, 
          incomplètes ou caduques. Le contenu de ce site est fourni à titre d'information générale uniquement 
          et ne doit pas être considéré ou utilisé comme seule base pour la prise de décisions sans consulter 
          des sources d'information plus importantes, plus exactes, plus complètes ou plus actuelles.
        </p>
        <p className="text-gray-300">
          Ce site peut contenir certaines données historiques. Par définition, les données historiques ne sont 
          pas actuelles et sont fournies uniquement à titre de référence.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 4 – Modifications du service et des prix</h2>
        <p className="text-gray-300 mb-4">
          Les prix de nos produits numériques (e-books) sont modifiables sans préavis.
        </p>
        <p className="text-gray-300 mb-4">
          Nous nous réservons le droit de modifier ou de mettre fin au Service (ou à une quelconque partie 
          de celui-ci) à tout moment et sans préavis.
        </p>
        <p className="text-gray-300">
          Nous ne pourrons être tenus responsables envers vous ou tout tiers de tout changement de prix, 
          ou encore de toute modification, suspension ou interruption du Service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 5 - Politique de remboursement des e-books</h2>
        <p className="text-gray-300 mb-4">
          Les e-books achetés sur notre site bénéficient d'une garantie de résultats sous conditions. 
          Vous pouvez demander un remboursement si :
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Vous avez suivi les instructions et méthodes décrites dans l'e-book pendant au moins 90 jours consécutifs.</li>
          <li>Vous fournissez des preuves claires et vérifiables montrant que vous avez appliqué les techniques et méthodes décrites dans l'e-book.</li>
        </ul>
        <p className="text-gray-300 mb-4">Les preuves acceptées incluent, mais ne sont pas limitées à :</p>
        <ul className="list-disc list-inside space-y-2 text-gray-300 mb-4">
          <li>Journaux d'activité personnels détaillant vos efforts et l'application des étapes énoncées dans l'e-book (exemple : suivi quotidien ou hebdomadaire).</li>
          <li>Captures d'écran ou photos montrant la mise en œuvre des techniques, outils ou exercices mentionnés dans l'e-book.</li>
          <li>Descriptions claires et précises des actions entreprises pour mettre en pratique les conseils prodigués.</li>
        </ul>
        <p className="text-gray-300 mb-4">
          Les preuves fournies doivent être complètes et détaillées afin d'évaluer la validité de votre demande.
        </p>
        <p className="text-gray-300 mb-4">
          Les preuves doivent être envoyées par e-mail à l'adresse suivante : info@furyoofficial.com.
        </p>
        <p className="text-gray-300 mb-4">
          Si, malgré ces efforts, aucun résultat notable n'est obtenu, nous rembourserons votre achat.
        </p>
        <p className="text-gray-300 mb-4">
          Les demandes de remboursement doivent être effectuées dans un délai maximum de 90 jours à compter 
          de la date d'achat. Passé ce délai, aucun remboursement ne sera accepté.
        </p>
        <p className="text-gray-300">
          Nous ne pourrons être tenus responsables des résultats obtenus si les méthodes décrites dans 
          l'e-book ne sont pas appliquées correctement ou de manière régulière.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Section 6 - Acceptation tacite</h2>
        <p className="text-gray-300">
          En effectuant un achat sur notre site, vous reconnaissez avoir lu et accepté les présentes 
          Conditions générales d'utilisation, y compris notre politique de remboursement. Cette acceptation 
          tacite constitue un accord légal entre vous et FuryoOfficial.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Article 18 – Loi applicable</h2>
        <p className="text-gray-300">
          Les présentes Conditions d'utilisation, ainsi que tout accord distinct par lequel nous vous 
          fournissons les Services sont régis et interprétés en vertu des lois de Bruxelles, Belgique.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Article 20 – Coordonnées</h2>
        <p className="text-gray-300">
          Les questions relatives aux Conditions d'utilisation doivent nous être envoyées à info@furyoofficial.com.
        </p>
      </section>
    </>
  );
}