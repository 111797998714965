interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
}

export async function sendContactForm(data: ContactFormData): Promise<string> {
  try {
    const response = await fetch('https://fueyo-backend.onrender.com/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json().catch(() => ({
      success: false,
      message: 'Erreur de format de réponse'
    })) as ApiResponse;

    if (!response.ok || !result.success) {
      throw new Error(result.message || `Erreur ${response.status}: ${response.statusText}`);
    }

    return result.message;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Erreur: ${error.message}`);
    }
    throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
  }
}