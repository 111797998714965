interface EbookCTAProps {
  stripeLink: string;
  ctaText: string;
}

export function EbookCTA({ stripeLink, ctaText }: EbookCTAProps) {
  return (
    <a 
      href={stripeLink}
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full py-3 mt-8 border-2 border-white hover:bg-white hover:text-black transition-all duration-300 text-center transform-gpu hover:scale-[1.02]"
      style={{
        transform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden'
      }}
    >
      {ctaText}
    </a>
  );
}
