import { motion } from 'framer-motion';
import { useRef } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useInView } from '../hooks/useInView';
import { AboutContent } from './about/AboutContent';
import { AboutLogo } from './about/AboutLogo';

export function About() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const { t } = useLanguage();

  return (
    <section 
      id="about" 
      className="py-24 bg-zinc-900 relative overflow-hidden w-full"
      ref={containerRef}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent opacity-50" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ 
            opacity: isVisible ? 1 : 0,
            y: isVisible ? 0 : 20 
          }}
          transition={{ duration: 0.7 }}
        >
          <span className="font-cinzel">{String(t('about.title'))}</span>
        </motion.h2>
        <div className="grid md:grid-cols-2 gap-12 items-start" ref={ref}>
          <AboutContent isVisible={isVisible} />
          <AboutLogo isVisible={isVisible} containerRef={containerRef} />
        </div>
      </div>
    </section>
  );
}